<template>
    <v-card flat tile :dark="data.value.style_block.dark_theme"  :style="getContainerStyle(data.value)">
      <v-container :class="getCustomCssClass(data.value)">
        <StreamfieldBlock v-for="(block, j) in data.value.rows" :key="j" :block="block"/>
      </v-container>
    </v-card>
</template>

<script>
import StreamfieldBlock from "./streamfieldBlock.vue";
//  StreamfieldBlock: () => import('./streamfieldBlock.vue')
export default {
  name: "ContainerBlock",
  props: ["data"],
  data: () => ({}),
  mounted() {
    console.log(this.data, "container data");
  },
  components: { StreamfieldBlock },
  methods: {
    getContainerStyle(container) {
      let style = "";
      if (container.style_block.background_image !== null)
        style +=
          " background-size: cover; background-image: url(" +
          container.style_block.background_image.file +
          ");";
      if (container.style_block.background_colour !== null)
        style +=
          " background-color: " + container.style_block.background_colour + ";";
    return style;
    },
    getCustomCssClass(containerValue) {
      if (containerValue.style_block.custom_css_classes !== null) {
        console.log(
          "getCustomCssClass()",
          containerValue.style_block.custom_css_classes
        );
        return containerValue.style_block.custom_css_classes;
      }
      return "";
    },
  },
};
</script>

<style scoped>
</style>